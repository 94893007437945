export const isMainDistrict = (cp) => {
  const districtArray = ['75000', '69000', '13000']

  return districtArray.includes(cp)
}

export const mainDistrictLink = (cp) => {
  if (cp.match(/^75\d{3}/)) {
    return { name: 'Paris (toute la ville)', link: '/ou-investir/villes/Paris/75000' }
  }

  if (cp.match(/^69\d{3}/)) {
    return { name: 'Lyon (toute la ville)', link: '/ou-investir/villes/Lyon/69000' }
  }

  if (cp.match(/^13\d{3}/)) {
    return { name: 'Marseille (toute la ville)', link: '/ou-investir/villes/Marseille/13000' }
  }
}

export const districtName = (cp) => {
  const district = +cp.slice(-2)

  if (district === 1) {
    return `${district}er`
  }
  return `${district}ème`
}

function generateDistricts(n, name, cp) {
  const array = [...Array(n).keys()]
  const districts = array.map((d) => ({
    name: `${name} ${d + 1}ème`,
    link: `/ou-investir/villes/${name}/${cp + (d + 1)}`,
  }))
  districts[0].name = `${name} 1er`
  return districts
}

export const getDistricts = (cp) => {
  if (cp.match(/^75\d{3}/)) {
    return generateDistricts(20, 'Paris', 75000)
  }
  if (cp.match(/^69\d{3}/)) {
    return generateDistricts(9, 'Lyon', 69000)
  }
  if (cp.match(/^13\d{3}/)) {
    return generateDistricts(16, 'Marseille', 13000)
  }
}
