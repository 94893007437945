<template>
  <main
    id="city"
    class="city-container"
  >
    <nuxt-lazy-hydrate when-idle>
      <LazyCity-SectionsEntry
        id="city-header"
        class="section"
        :city="city"
        :city-name="cityName"
      />
    </nuxt-lazy-hydrate>

    <div
      v-if="city.ville"
      class="content"
    >
      <div class="sticky-content hide-on-mobile">
        <nuxt-lazy-hydrate when-visible>
          <LazyAnnonces
            class="section hide-on-mobile annonces"
            :city="city.ville"
            :postal-code="city.code_postal"
            :is-mobile="true"
          />
          <div class="separator" />
          <LazyArticle-toc
            :titles="titles"
            :active-title="activeTitle"
            @click="updateObserver"
          />
        </nuxt-lazy-hydrate>
      </div>

      <div class="sections">
        <nuxt-lazy-hydrate when-visible>
          <LazyCity-SectionsParagraphBlocs
            v-if="analysis && analysis.resume"
            id="resume"
            class="section anchor"
            :title="analysisTitle"
            :text="analysis.resume"
            background="secondary"
          />
        </nuxt-lazy-hydrate>

        <BannerIconDescriptionCta
          type="primary"
          class="section"
          :title="banner.title"
          :description="banner.description"
          :cta-text="banner.link"
          @handle-click="handleClick"
        />

        <nuxt-lazy-hydrate
          v-if="analysis && analysis.rendement"
          when-visible
        >
          <LazyCity-SectionsParagraphBlocs
            id="investment"
            class="section anchor"
            :title="investmentTitle"
            :text="analysis.rendement"
          />
        </nuxt-lazy-hydrate>
        <LazyCity-SectionsNoAnalysis
          v-else
          class="section anchor"
          :city="city"
          :city-name="cityName"
        />

        <nuxt-lazy-hydrate when-visible>
          <LazyAnnonces
            class="section hide-on-desktop"
            :city="city.ville"
            :postal-code="city.code_postal"
            column
          />
        </nuxt-lazy-hydrate>

        <nuxt-lazy-hydrate when-visible>
          <LazyCity-SectionsParagraphBlocs
            v-if="analysis && analysis.population"
            id="demography"
            class="section anchor"
            :title="demographyTitle"
            :text="analysis.population"
          />
        </nuxt-lazy-hydrate>

        <nuxt-lazy-hydrate when-visible>
          <LazyCity-SectionsParagraphBlocs
            v-if="analysis && analysis.patrimoine"
            id="estate"
            class="section anchor"
            :title="estateTitle"
            :text="analysis.patrimoine"
          />
        </nuxt-lazy-hydrate>

        <nuxt-lazy-hydrate when-visible>
          <div class="section small-banner">
            <LazyCity-ComponentsSmallBanner
              v-for="(banner, index) in smallBanner"
              :key="'banner' + index"
              type="secondary"
              :title="banner.title"
              :text="banner.text"
              :link="banner.link"
              :link-text="banner.linkText"
            />
          </div>
        </nuxt-lazy-hydrate>

        <client-only>
          <LazyCity-SectionsStrategy
            v-if="hasBestStrategy"
            id="strategy"
            class="section anchor"
            :city="city"
            :city-name="cityName"
            :revenus-input="revenus"
            :title="strategyTitle"
            @change-revenu="handleRevenusChange"
          />
        </client-only>

        <nuxt-lazy-hydrate when-visible>
          <tub-carousel
            :city="route.params.city"
            :postal-code="route.params.cp"
          />
        </nuxt-lazy-hydrate>

        <client-only>
          <LazyCity-SectionsMarketStudy
            v-if="hasGraphes"
            id="marketStudy"
            class="section anchor"
            :city="city"
            :title="marketStudyTitle"
            :city-name="cityName"
          />
        </client-only>

        <client-only>
          <LazyCity-SectionsSchoolAndParking
            id="schoolParking"
            class="section anchor"
            :title="schoolParkingTitle"
            :city-input="city"
            :city-name="cityName"
          />
        </client-only>

        <nuxt-lazy-hydrate when-visible>
          <LazyCity-SectionsDistricts
            v-if="city.hasDistrict"
            id="districts"
            class="section anchor"
            :city="city"
            :title="districtTitle"
          />
        </nuxt-lazy-hydrate>

        <BannerIconDescriptionCta
          type="primary"
          class="section"
          :title="banner.title"
          :description="banner.description"
          :cta-text="banner.link"
          @handle-click="handleClick"
        />

        <nuxt-lazy-hydrate when-visible>
          <LazyCity-SectionsPastAnalysis
            v-if="!city || !city.analyses || Object.keys(city.analyses).length > 1"
            id="pastAnalysis"
            class="section anchor"
            :city="city"
            :city-name="cityName"
            :title="pastAnalysisTitle"
          />
        </nuxt-lazy-hydrate>
      </div>
    </div>
    <nuxt-lazy-hydrate when-visible>
      <lazy-agency-list-section
        v-if="city.ville"
        :city="city.ville"
        :postal-code="city.code_postal"
      />
    </nuxt-lazy-hydrate>
  </main>
</template>

<script setup>
import { currentYear } from '@/helpers/currentYear'
import { useAuthStore } from '../../../../../stores/auth'
import { districtName, isMainDistrict } from '@/helpers/citiesWithDistricts'

const investApi = useInvestApi()
const route = useRoute()
const config = useRuntimeConfig()
const authStore = useAuthStore()

const observer = ref(null)
const activeTitle = ref('resume')
const revenus = ref(30000)

const { data: city } = await useLazyAsyncData(
  `city-${route.params.city}-${route.params.cp}`,
  () =>
    investApi.getCity({
      city: route.params.city,
      postalCode: route.params.cp,
      revenus: 30000,
    }),
  {
    default: () => ({}),
  },
)

const cityName = computed(() => {
  const name = city.value.ville ?? route.params.city
  const cp = city.value.code_postal ?? route.params.cp

  if (city.value.hasDistrict && !isMainDistrict(cp)) {
    return `${name} ${districtName(cp)}`
  }

  return name
})

const loggedIn = computed(() => authStore.loggedIn)

const banner = reactive({
  title: `Envie de <strong>réussir votre investissement</strong> à ${cityName.value} ?`,
  description:
    'Un chasseur immobilier, spécialiste de cette zone, déniche pour vous le bien idéal dont le rendement locatif a été vérifié ! Biens vides, loués, meublés ou avec travaux : nous avons la solution pour vous.',
  link: 'Acheter un bien rentable',
})

const investmentTitle = computed(() => `<strong>L’investissement locatif</strong> à ${cityName.value}`)
const demographyTitle = computed(() => `<strong>Démographie</strong> à ${cityName.value}`)
const estateTitle = computed(() => `<strong>Patrimoine immobilier</strong> à ${cityName.value}`)
const analysisTitle = computed(
  () => `Faut-il <strong>investir à ${cityName.value} en ${currentYear} ?</strong>`,
)
const schoolParkingTitle = computed(() => `<strong>Autres informations</strong> à ${cityName.value}`)
const pastAnalysisTitle = computed(
  () => `<strong>Précédentes analyses</strong> d’investissement locatif à ${cityName.value}`,
)
const marketStudyTitle = computed(
  () => `Étude
      <strong>du marché locatif</strong>
      à ${cityName.value}`,
)
const strategyTitle = computed(
  () => `<strong>Stratégies d’investissement</strong>
      locatif à ${cityName.value}`,
)
const districtTitle = computed(() => 'Découvrir <strong>les arrondissements</strong>')
const titles = computed(() =>
  [
    analysis.value && analysis.value.resume
      ? {
          anchor: 'resume',
          title: strippedHtml(analysisTitle.value),
          level: 1,
        }
      : null,
    analysis.value && analysis.value.rendement
      ? {
          anchor: 'investment',
          title: strippedHtml(investmentTitle.value),
          level: 1,
        }
      : null,
    analysis.value && analysis.value.population
      ? {
          anchor: 'demography',
          title: strippedHtml(demographyTitle.value),
          level: 1,
        }
      : null,
    analysis.value && analysis.value.patrimoine
      ? {
          anchor: 'estate',
          title: strippedHtml(estateTitle.value),
          level: 1,
        }
      : null,
    hasBestStrategy.value
      ? {
          anchor: 'strategy',
          title: strippedHtml(strategyTitle.value),
          level: 1,
        }
      : null,
    hasGraphes.value
      ? {
          anchor: 'marketStudy',
          title: strippedHtml(marketStudyTitle.value),
          level: 1,
        }
      : null,
    {
      anchor: 'schoolParking',
      title: strippedHtml(schoolParkingTitle.value),
      level: 1,
    },
    city.value.hasDistrict
      ? {
          anchor: 'districts',
          title: strippedHtml(districtTitle.value),
          level: 1,
        }
      : null,
    Object.keys(city.value.analyses).length > 1
      ? {
          anchor: 'pastAnalysis',
          title: strippedHtml(pastAnalysisTitle.value),
          level: 1,
        }
      : null,
  ].filter(Boolean),
)

const strippedHtml = (text) => {
  const regex = /(<([^>]+)>)/gi

  return text.replace(regex, '')
}

const analysis = computed(() => {
  if (!city.value || !city.value.analyses || Object.keys(city.value.analyses).length === 0) return []

  return Object.values(city.value.analyses)[0]
})
const hasGraphes = computed(
  () => city.value && city.value.graphes && Object.keys(city.value.graphes).length > 1,
)
const hasBestStrategy = computed(() => hasGraphes.value && !!city.value.graphes.bestStrategy?.length)

const handleRevenusChange = (revenus) => (revenus.value = revenus)

const smallBanner = [
  {
    title: '<span>Plugin</span> de rendement',
    text: 'Affichez les principaux indicateurs de performance (rendement brut, estimation de loyer, prix au m2…) sur les annonces LeBonCoin, SeLoger…',
    link: 'https://horiz.io/ressources/plugin-rendement-locatif',
    linkText: 'Télécharger',
  },
  {
    title: '<span>Application</span> d’alertes immobilières',
    text: 'Ville, budget, taux de rendement, type de bien, montant estimé des travaux… Soyez alerté en temps réel des annonces immo les plus rentables.',
    link: 'https://horiz.io/ressources/application-mobile-rentabilite-locative',
    linkText: 'Télécharger',
  },
]

const handleObserver = (entries = []) => {
  if (!import.meta.client) {
    return
  }

  if (!observer.value) {
    observer.value = new IntersectionObserver(handleObserver, { rootMargin: '-60% 0% -40% 0%' })
  }

  const elements = document.querySelectorAll('.anchor')
  elements.forEach((element) => observer.value.observe(element))

  for (const entry of entries.reverse()) {
    if (entry.isIntersecting) {
      activeTitle.value = entry.target.id
      break
    }
  }
}

const updateObserver = () => setTimeout(() => handleObserver(), 200)

watch(city, (newCity, oldCity) => {
  if (oldCity !== newCity) {
    updateObserver()
  }
})

onMounted(async () => {
  if (import.meta.client) {
    handleObserver()

    const tracking = useTracking()
    await tracking.trackAfterMounted('Page ville loaded', {
      anonymous: loggedIn.value ? 'no' : 'yes',
      ville: city.value.ville,
      codePostal: city.value.code_postal,
    })
  }
})

const handleClick = () => {
  window.open('https://s.horiz.io/chasseimmo_home', '_blank')
}

onBeforeUnmount(() => {
  if (import.meta.client && observer.value) {
    observer.value.disconnect()
  }
})

// Metas
const title = computed(() => `Analyse Investissement locatif immobilier à ${cityName.value}`)
const url =
  config.public.hpBaseUrl +
  (route.path.charAt(route.path.length - 1) === '/'
    ? route.path.substring(0, route.path.length - 1)
    : route.path)

useMeta({
  title,
  description: () =>
    `Vous souhaitez investir à ${cityName.value} ? Découvrez nos analyses dédiées et calculez votre rendement locatif grâce à notre simulateur N°1.`,
  image: 'Horizio-ou-investir-immobilier.jpg',
  imageAlt: 'Horiz.io, pilotez votre gestion locative en ligne',
  url,
  appleItunesApp: 'app-id=1596721394',
})

useHead({
  title,
  link: [
    {
      rel: 'canonical',
      href: url,
    },
  ],
})

useJsonld([
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Horiz',
    alternateName: 'Rendement Locatif',
    url,
    logo: `${config.public.hpBaseUrl}/imgs/logo.png`,
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: 'Horiz',
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    url: `${config.public.hpBaseUrl}/ou-investir`,
  },
  {
    '@context': 'http://www.schema.org',
    '@type': 'Product',
    name: 'Horiz',
    brand: ' Horiz',
    image: `${config.public.hpBaseUrl}/imgs/logo.png`,
    description: "/html/head/meta[@name='description']/@content",
    aggregateRating: {
      '@type': 'aggregateRating',
      ratingValue: '5',
      reviewCount: '62',
      bestRating: '5 ',
    },
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: config.public.hpBaseUrl,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Ou investir ?',
        item: `${config.public.hpBaseUrl}/ou-investir`,
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'Investissement locatif à ' + cityName.value + ' : Analyse',
        item: url,
      },
    ],
  },
])
</script>

<script>
export default {
  middleware: [
    function ({ params, redirect }) {
      if (params.city === 'undefined' || params.cp === 'undefined') {
        redirect('/ou-investir')
      }
    },
  ],
}
</script>

<style lang="less" scoped>
.city-container {
  position: relative;
  min-height: 70vh;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;

  .content {
    .app-padding();
  }

  @media @bp-desktop {
    .content {
      display: flex;

      .sticky-content {
        position: sticky;
        margin-top: 40px;
        height: calc(100% - 120px);
        top: 112px;
        min-width: 520px;
      }
    }
  }

  :deep(h2) {
    margin-bottom: 20px;
  }

  .section-resume {
    border-radius: 8px;
    background-color: var(--ds-color-primary-25);
  }

  .sections {
    overflow: hidden;

    @media @bp-mobile {
      .app-padding();
    }

    .section {
      margin: 40px 0;
    }

    .small-banner {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media @bp-desktop {
        flex-direction: row;
      }
    }
  }
}

.separator {
  height: 1px;
  width: 100%;
  background: #e8eaed;
  margin-top: 32px;
  margin-bottom: 32px;
}

@media @bp-desktop {
  .city-container {
    .content {
      max-width: 1440px;
    }

    .sections {
      max-width: 820px;
    }
  }
}
</style>
